export default function DeleteIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="18.311" height="25.285" viewBox="0 0 18.311 25.285">
    <g id="Gruppe_52" data-name="Gruppe 52" transform="translate(-697.343 -755.715)">
      <path id="Pfad_9" data-name="Pfad 9" d="M8976,4783v14.544a4.073,4.073,0,0,0,.626,2.383,3.21,3.21,0,0,0,2.139.778h10.928a2.878,2.878,0,0,0,2-.778,4.369,4.369,0,0,0,.614-2.383V4783" transform="translate(-8277.651 -4020.706)" fill="none" stroke="#000" stroke-width="2"/>
      <line id="Linie_13" data-name="Linie 13" x2="16" transform="translate(698.349 759.255)" fill="none" stroke="#000" stroke-width="2"/>
      <path id="Pfad_10" data-name="Pfad 10" d="M8992,4761.922V4759h6.986v2.922" transform="translate(-8288.994 -4002.285)" fill="none" stroke="#000" stroke-width="2"/>
      <line id="Linie_14" data-name="Linie 14" y2="12" transform="translate(703.156 764)" fill="none" stroke="#000" stroke-width="1.5"/>
      <line id="Linie_15" data-name="Linie 15" y2="12" transform="translate(706.499 764)" fill="none" stroke="#000" stroke-width="1.5"/>
      <line id="Linie_16" data-name="Linie 16" y2="12" transform="translate(709.842 764)" fill="none" stroke="#000" stroke-width="1.5"/>
    </g>
  </svg>
  
;  
}