import { getSessionId, getUsername } from "./loginManager";
import { openPopUp } from "../components/popUp";
import { sendRequest } from "./apiManager";

function setHideClub(id, value, onFinish) {
    sendRequest("club/hide/" + id + "/" + value, "DELETE", {}, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function getClubsByOwner(username, onFinish) {
    sendRequest("club/owner/" + username, "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.clubs);
        }else {
            openPopUp(r.message);
        }
    });
}

function getClubInfo(id, onFinish) {
    sendRequest("club/" + id, "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data);
        }else {
            openPopUp(r.message);
        }
    });
}

function getClubCategories(onFinish) {
    sendRequest("club/category", "GET", {}, (r) => {
        if(r.status == 200) {
            var categoriesJSON = r.data.categories;
            var categories = [];
            for(var i = 0; i < categoriesJSON.length; i++) {
                categories.push(categoriesJSON[i].name);
            }
            onFinish(categories);
        }else {
            openPopUp(r.message);
        }
    });
}

function updateClub(id, data, onFinish) {
    sendRequest("club/" + id, "PATCH", data, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function hasClub(username, onFinish) {
    getClubsByOwner(username, (clubs) => {
        for(var i = 0; i < clubs.length; i++) {
            onFinish(clubs[i].id);
            return;
        }
        onFinish(-1);
    });
}

function getAllClubs(onFinish) {
    sendRequest("club/all", "GET", {}, (r) => {
        if(r.status == 200) {
            var output = [];
            for(var key in r.data) {
                Array.prototype.push.apply(output, r.data[key])
            }
            onFinish(output);
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function registerClubWUser(category, user, onFinish) {
    sendRequest("club", "POST", {
        "name": getUsername(),
        "title": getUsername() + " Verein",
        "subtitle": "",
        "content": "",
        "category": category,
        "logoLink": null,
        "imagesLinks": "",
        "street": "",
        "zip": "",
        "city": "",
        "addressAddition": "",
        "news": "",
        "open": "",
        "contact": "",
        "imageAttributes": "",
        "user": user
    }, (r) => {
        if(r.status == 201) {
            onFinish(r.data);
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function registerClub(category, onFinish) {
    sendRequest("club", "POST", {
        "name": getUsername(),
        "title": getUsername() + " Verein",
        "subtitle": "",
        "content": "",
        "category": category,
        "logoLink": null,
        "imagesLinks": "",
        "street": "",
        "zip": "",
        "city": "",
        "addressAddition": "",
        "news": "",
        "open": "",
        "contact": "",
        "imageAttributes": ""
    }, (r) => {
        if(r.status == 201) {
            onFinish(r.data);
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function addClubCategory(category, onFinish) {
    sendRequest("club/category", "POST", {
        "name": category
    }, (r) => {
        if(r.status == 201) {
            onFinish();
        }else if(r.status == 226){
            openPopUp("Eine Kategorie mit diesem Namen existiert bereits");
        }else {
            openPopUp(r.status);
        }
    }, getSessionId());
}

function deleteClubCategory(category, onFinish) {
    sendRequest("club/category/" + category, "DELETE", {}, (r) => {
        if(r.status == 200) {
            onFinish();
        }else if(r.status == 409){
            openPopUp("Es dürfen keine Vereine mehr der Kategorie zugeordnet sein");
        }else {
            openPopUp(r.status);
        }
    }, getSessionId());
}

export {registerClubWUser, addClubCategory, deleteClubCategory, registerClub, getAllClubs, setHideClub, hasClub, updateClub, getClubsByOwner, getClubInfo, getClubCategories}