import React from "react";
import ImageLink, { getImageUrl } from "./imageLink";

import PenIcon from "../assets/icons/pen";

import "../style/editImageBox.css";
import IconButton from "./iconButton";
import ArrowBigIcon from "../assets/icons/arrowBig";

export default function EditImageBox(props) {
    return <div className={"edit-image-box" + (props.notclickable ? " nohover" : "")}>
        <div className="edit-image" onClick={props.onClick ? props.onClick : () => {}} style={{
        "backgroundImage": "url(" + getImageUrl(props.link) + ")"
    }}>
        <PenIcon/>
        <p>{props.editText}</p>
    </div>
    {props.moveable ? <>
        <div className={"move" + (props.left ? " disabled" : "")} onClick={() => {
            if(!props.left) {
                props.onMove(true);
            }
        }}><ArrowBigIcon/></div>
        <div className={"move right" + (props.right ? " disabled" : "")} onClick={() => {
            if(!props.right) {
                props.onMove(false);
            }
        }}><ArrowBigIcon/></div>
    </> : <></>}
    </div>;
}