import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ActionPage, { closeActionPage } from "../../components/actionPage";
import Button from "../../components/button";
import { openPopUp } from "../../components/popUp";
import TextField from "../../components/textField";
import { changePassword } from "../../content/accountManager";
import { relogin } from "../../content/loginManager";

export default function ChangePasswordAction(props) {

    const [adminPassword, setAdminPassword] = useState();
    const [repeatPassword, setRepeatPassword] = useState();
    const [password, setPassword] = useState();

    function executeChangePassword() {
        if(repeatPassword != password) {
            openPopUp("Die eingegebenen Passwörter stimmen nicht überein");
            return;
        }
        relogin(adminPassword, () => {
            changePassword(props.username, password, ()=> {
                openPopUp("Passwort erfolgreich aktualisiert");
                closeActionPage();
            });
        });
    }

    return <ActionPage>
        <TextField password onChange={setAdminPassword} label="Admin Passwort" onEnter={executeChangePassword}/>
        <TextField second password onChange={setPassword} label="neues Passwort" onEnter={executeChangePassword}/>
        <TextField second password onChange={setRepeatPassword} label="neues Passwort wiederholen" onEnter={executeChangePassword}/>
        <Button onClick={executeChangePassword}>Passwort Ändern</Button>
    </ActionPage>;

}