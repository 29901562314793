import React, {useEffect, useReducer, useRef, useState} from "react";
import { openActionPage } from "../../components/actionPage";
import EditImageBox from "../../components/editImageBox";

import "../../style/clubContent.css";
import EditImageAction from "../action/editImage";
import EditLogoAction from "../action/editLogo";
import SingleAddIcon from "../../assets/icons/singleAdd"
import { submitClubChange, submitClubChanges } from "../dashboardClub";
import AddImageAction from "../action/addImage";
import ClubImage from "../../content/clubImage";
import TextField from "../../components/textField";
import EditImageRow from "../../components/editImageRow";

function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

function convertImageAttributes(arr) {
    var output = "";
    for(var i = 0; i < arr.length; i++) {
        output += arr[i][0] + "," + arr[i][1] + ";";
    }
    if(output.length > 0) {
        output = output.substring(0, output.length - 1);
    }
    return output;
}

function convertImageAttributesFromString(s, length) {
    var output = [];
    if(!s) {
        for(var i = 0; i < length; i++) {
            output.push([false, "Bild"]);
        }
        return output;
    }
    var split = s.split(";");
    for(var i = 0; i < split.length; i++) {
        output.push([split[i].split(",")[0], split[i].split(",")[1]]);
    }
    return output;
}

function ClubContent(props) {

    const [loadData, setLoadData] = useState(0);
    const [logo, setLogo] = useState(undefined);
    const [images, setImages] = useState([]);
    const [content, setContent] = useState(props.data.content);

    useEffect(() => {
        if(loadData == 1) {

            var imageLinks = props.data.imageLinks.split(";");
            var imageAttributes = props.data.imageAttributes.split(";");
            var imageArr = [];
            for(var i = 0; i < imageLinks.length; i++) {
                var attribute = imageAttributes[i];
                if(!attribute) continue;
                if(!attribute) {
                    attribute = [false, "Bild"];
                }
                imageArr.push(new ClubImage(imageLinks[i], attribute.split(",")[0], attribute.split(",")[1]));
            }

            setImages(imageArr);
            document.dispatchEvent(new CustomEvent("updateImageRowDefault", {
                detail: {
                    images: imageArr
                }
            }))
            setLoadData(2);
        }
    })

    if(!props.data || props.data.imageLinks == undefined) {
        return <></>;
    }
    if(images && images.length == 0 && props.data.imageLinks.length > 0 && loadData == 0) {
        setLoadData(1);
    }
    function submitImagesChange() {
        
        var linkTxt = "", attributesTxt = "";
        for(var i = 0; i < images.length; i++) {
            linkTxt += images[i].getLink() + ";";
            attributesTxt += (images[i].isFullscreen() + "," + images[i].getDescription() + ";");
        }
        if(linkTxt.length > 0) {
            linkTxt = linkTxt.substring(0, linkTxt.length-1);
        }
        if(attributesTxt.length > 0) {
            attributesTxt = attributesTxt.substring(0, attributesTxt.length-1);
        }
        submitClubChanges({
            "imageAttributes": attributesTxt,
            "imagesLinks": linkTxt
        });
    }
    return <div className="club-content">
        <p className="section-name">Logo</p>
        <EditImageBox onClick={() => {
            openActionPage(<EditLogoAction logo={logo ? logo : props.data.logo} changeLogo={(v) => {
                submitClubChange("logo", v);
                setLogo(v);
            }}/>);
        }} link={logo == "null" ? "-1/2d8a97a4-1e0b-46a6-9335-8f3497eb2a16" : props.data.logo} editText={"Logo bearbeiten"}/>
        <EditImageRow maxImg={4} label="Bilder (max 4)" defaultValue={images} onChange={(v) => {
            setImages(v);
            submitImagesChange();
        }}/>
        <TextField autogrow onChange={(v) => {
            submitClubChange("content", v);
        }} label="Inhalt" defaultValue={props.data.content} textarea placeholder="Beschreibe wer ihr seid und was ihr macht"/>
    </div>;
    
}

export {convertImageAttributes, convertImageAttributesFromString}
export default ClubContent;