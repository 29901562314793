import React, {useEffect, useState} from "react";
import CloseIcon from "../assets/icons/close";

import "../style/tagList.css";

export default function TagList(props) {

    const [defaultTags, setDefaultTags] = useState(props.defaultValue);
    const [tags, setTags] = useState(props.defaultValue ? props.defaultValue : []);

    useEffect(() => {
        if(defaultTags != props.defaultValue) {
            setTags(props.defaultValue);
            setDefaultTags(props.defaultValue);
        }
    })

    function updateTags(tags) {
        setTags(tags);
        props.onChange(tags);
    }

    return <div className="tag-list">
        {props.label ? <p>{props.label}</p> : <></>}
        <div className="tags">

            {tags.map(e => {
                return <div className="tag">{e}<CloseIcon onClick={() => {
                    var temp = [];
                    for(var i = 0; i < tags.length; i++) {
                        if(tags[i] == e) continue;
                        temp.push(tags[i]);
                    }
                    updateTags(temp);
                }}/></div>
            })}
            
            <input placeholder="eingeben und mit Enter bestätigen" onKeyUp={(e) => {
                var keyCode = e.code || e.key;
                if(keyCode != "Enter") return;
                if(e.target.value.length == 0) return;
                if(tags.includes(e.target.value)) return;
                var temp = [];
                for(var i = 0; i < tags.length; i++) {
                    temp.push(tags[i]);
                }
                temp.push(e.target.value);
                updateTags(temp);
                e.target.value = "";
            }}/>
        </div>
    </div>;
}

function convertTagListToString(list) {
    var tagList = "";
    if(list != undefined) {
        
        for(var i = 0; i < list.length; i++) {
            tagList += list[i] + ";";
        }
    }
    if(tagList.length > 0) {
        tagList = tagList.substring(0, tagList.length - 1);
    }
    return tagList;
}

export {convertTagListToString}