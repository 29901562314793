import React, {useEffect, useRef, useState} from "react";
import Calendar from "react-calendar";

import "../style/dashboardCalendar.css";

import ArrowBigIcon from "../assets/icons/arrowBig";
import { openPopUp } from "./popUp";

export default function DashboardCalendar(props) {

    const firstExecute = useRef(false);
    const [selectedDates, setSelectedDates] = useState(props.defaultValue ? props.defaultValue : []);

    useEffect(() => {
        if(!firstExecute.current) {
            props.onChange(props.defaultValue);
            firstExecute.current = true;
        }
        if(props.startDate) {
            if(selectedDates.length == 0) {
                var temp = [];
                temp.push(props.startDate);
                setSelectedDates(temp);
            }
        }
        document.addEventListener("addDatesDashboardCalendar", (e) => {
            var temp = selectedDates.concat(e.detail.dates);
            setSelectedDates(temp);
            console.log(temp);
            props.onChange(temp);
        })
    })

    function toggleSelectedDate(date) {
        if(date < props.startDate) {
            openPopUp("Der Termin kann sich nur nach dem ersten Termin wiederholen");
            return;
        }
        var temp = selectedDates;
        var includes = -1;
        for(var i = 0; i < temp.length; i++) {
            if(formatDate(temp[i]) == formatDate(date)) {
                includes = i;
                break;
            }
        }
        var isStartDate = props.startDate.getMonth() == date.getMonth() && props.startDate.getFullYear() == date.getFullYear() && date.getDate() == props.startDate.getDate();
        if(isStartDate) return;
        if(includes != -1) {
            temp.splice(includes, 1);
            
        }else {
            temp.push(date);
        }
        setSelectedDates(temp);
        var temp2 = [];
        for(var i = 0; i < temp.length; i++) {
            if(props.startDate.getMonth() == temp[i].getMonth() && props.startDate.getFullYear() == temp[i].getFullYear() && temp[i].getDate() == props.startDate.getDate()) {
                continue;
            }
            temp2.push(temp[i]);
        }
        props.onChange(temp2);
    }

    return <Calendar defaultActiveStartDate={props.startDate} onChange={toggleSelectedDate} nextLabel={<ArrowBigIcon/>} prevLabel={<ArrowBigIcon/>} maxDetail="month" minDetail="month" tileClassName={({ date, view }) => {
        const format = formatDate(date);
        for(var i = 0; i < selectedDates.length; i++) {
            if(formatDate(date) == formatDate(selectedDates[i])) {
                return "highlight";
            }
        }
      }}></Calendar>;

}

function formatDate(date) {
    return (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/" + (date.getMonth()+1 < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) + "/" + date.getFullYear();
}