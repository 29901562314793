import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Page from "../components/page";

import "../style/dashboardClub.css";
import ClubGeneral from "./club/general";
import { getClubInfo, updateClub } from "../content/clubManager";
import ClubSidebar from "./club/sidebar";
import SaveIcon from "../assets/icons/save";
import { openPopUp } from "../components/popUp";
import { jsonEmpty } from "../utils/generalUtils";
import ClubContent from "./club/content";
import { setUnsafedChanges } from "../content/unsafedChanges";
import SaveButton from "../components/saveButton";

function submitClubChange(key, value) {
    var json = {};
    json[key] = value;
    submitClubChanges(json);
}

function submitClubChanges(json) {
    const event = new CustomEvent("updateClubData", { detail: json});
    document.dispatchEvent(event);
}

function DashboardClub(props) {

    const [data, setData] = useState({});
    const [newData, setNewData] = useState({});

    const navigate = useNavigate();

    const active = props.active;

    const {id} = useParams();

    const changeMade = !jsonEmpty(newData);
    function hasUnsavedChanges() {
        return !jsonEmpty(newData);
    }

    function getNewData() {
        return newData;
    }

    useEffect(() => {
        if(jsonEmpty(data)) {
            getClubInfo(id, (d) => {
                setData(d);
            });
        }

        document.addEventListener("updateClubData", (e) => {
            var newDataTemp = JSON.parse(JSON.stringify(newData));
            for(var i = 0; i < Object.keys(e.detail).length; i++) {
                var key = Object.keys(e.detail)[i];
                var value = e.detail[key];
                newDataTemp[key] = value;
                if(value == data[key]) {
                    
                    delete newDataTemp[key];
                }

            }
            setNewData(newDataTemp);
            setUnsafedChanges(!jsonEmpty(newDataTemp));
        });
    });

    const combinedData = {};
    for(var key in data) {
        if(newData.hasOwnProperty(key)) {
            combinedData[key] = newData[key];
        }else {
            combinedData[key] = data[key];
        }
    }

    if(Object.keys(combinedData).length == 0) return <></>;

    return <Page id="club-page">
        <div className="main-box">
            <div className="header">
                <a onClick={() => {navigate("/club/" + id + "/general")}} className={active == 0 ? "active" : ""}>Allgemein</a>
                <a onClick={() => {navigate("/club/" + id + "/sidebar")}} className={active == 1 ? "active" : ""}>Sidebar</a>
                <a onClick={() => {navigate("/club/" + id + "/content")}} className={active == 2 ? "active" : ""}>Inhalte</a>
            </div>
            <div className="content">
                {active == 0 ? <ClubGeneral data={combinedData}/> : <></>}
                {active == 1 ? <ClubSidebar data={combinedData}/> : <></>}
                {active == 2 ? <ClubContent data={combinedData}/> : <></>}
            </div>
            <SaveButton onClick={() => {
                if(!changeMade) return;
                updateClub(id, newData, () => {
                    openPopUp("Verein erfolgreich aktualisiert");
                    setData({});
                    setNewData({});
                    setUnsafedChanges(false);
                });
            }} changeMade={changeMade}/>
        </div>
    </Page>;
}

export default DashboardClub;
export {submitClubChange, submitClubChanges}