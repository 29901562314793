import { useEffect } from "react";
import { openFirstHeaderLink } from "../components/header";
import { getCategory } from "../content/loginManager";

export default function DefaultPage() {
    useEffect(() => {
        if(getCategory() == undefined)  {
            window.open("/login", "_self");
        }else {
            openFirstHeaderLink(getCategory());

        }
    })
    return <></>;
}