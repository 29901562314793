import { openPopUp } from "../components/popUp";
import { sendRequest } from "./apiManager";
import { storeUser, unstoreUser } from "./cookieManager";
import { loadUserFromCookie } from "./cookieManager";
import { getCurrentPageName } from "./pageManager";

var user, session, accountType, passwordExpires;

function logout() {
    sendRequest("account/logout", "POST", {}, (r) => {
        if(r.status == 401) {
            openPopUp("You entered the wrong password");
            window.open("/login", "_self");
        }else if(r.status == 200) {
            window.open("/login", "_self");
            unstoreUser();
        }else {
            openPopUp("An error occurred");
        }
    }, session);
}

function relogin(password, onFinish) {
    login(user, password, passwordExpires, onFinish);
}

function login(username, password, storePassword, onFinish) {
    sendRequest("account/login", "POST", {
        "username": username,
        "password": password
    }, (r) => {
        if(r.status == 401) {
            openPopUp("You entered the wrong password");
        }else if(r.status == 200) {
            user = username;
            session = r.data.sessionId;
            accountType = r.data.category;
            storeUser(username, r.data.sessionId, r.data.category, storePassword);
            onFinish();
        }else {
            openPopUp("An error occurred");
        }
    }, "")
}

function checkSession(sessionId, onFinish) {
    sendRequest("account/check-session", "POST", {}, (r) => {
        onFinish(r.status == 200);
    }, sessionId)
}

function loadUser(onFinish) {
    var pageName = getCurrentPageName();
    loadUserFromCookie((username, sessionId, category) => {
        if(username && sessionId) {
            user = username;
            session = sessionId;
            accountType = category;
            checkSession(session, (r) => {
                if(onFinish) {
                    onFinish();
                }
                if(r) {
                    if(pageName == "login") {
                        window.open("/dashboard", "_self");
                    }

                }else {
                    if(pageName != "login") {
                        window.open("/login", "_self");
                    }
                }
            });
        }else {
            if(pageName != "login") {
                window.open("/login", "_self");
            }
            if(onFinish) {
                onFinish();
            }
        }
    });
}

function changeOwnPassword(newPassword, onFinish) {
    sendRequest("account/changepassword", "POST", {
        "newPassword": newPassword
    }, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function getSessionId() {
    return session;
}

function getUsername() {
    return user;
}

function getCategory() {
    return accountType;
}

export {relogin, login, loadUser, getSessionId, getUsername, checkSession, logout, getCategory, changeOwnPassword}