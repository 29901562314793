import React, {useEffect, useState} from "react";
import EditImageBox from "./editImageBox";
import { openActionPage } from "./actionPage";
import AddImageAction from "../pages/action/addImage";
import ClubImage from "../content/clubImage";
import EditImageAction from "../pages/action/editImage";
import SingleAddIcon from "../assets/icons/singleAdd";

import { array_move } from "../utils/arrayUtils";

import "../style/editImageRow.css";

export default function EditImageRow(props) {

    const [images, setImages] = useState(props.defaultValue ? props.defaultValue : []);

    function updateImages(arr) {
        setImages(arr);
        props.onChange(arr);
    }

    useEffect(() => {
        document.addEventListener("updateImageRowDefault", (e) => {
            setImages(e.detail.images);
        })
    })

    return <>
    <p className="edit-image-row-title">{props.label}</p>
    <div className="edit-img-row" style={{
        width: (130 * props.maxImg + 10*(props.maxImg-1) + 10) + "px"
    }}>
        {images.map((e, i) => {
            return <EditImageBox onMove={(left) => {
                updateImages(array_move(images, i, i + (left ? -1 : 1)));
                
            }} onClick={() => {
                openActionPage(<EditImageAction onCancel={props.onCancel} onChange={(f, d) => {
                    var arr = images;
                    arr[i].setDescription(d);
                    arr[i].setFullscreen(f == 1);
                    updateImages(arr);
                }} attributes={images[i].getAttributes()} image={e.getLink()} onDelete={() => {
                    var temp = []
                    if(images.length > 0) {
                        temp = images;
                        temp.splice(i, 1);
                    }
                    updateImages(temp);
            }}/>);
            }} left={i == 0} right={i == images.length - 1} moveable link={e.getLink()} editText={"Bild bearbeiten"}/>;
            })}
            {images.length < props.maxImg ? <div className="add-image" onClick={() => {
                openActionPage(<AddImageAction onCancel={props.onCancel} onFinish={(v) => {
                    var temp = images;
                    temp.push(new ClubImage(v, false, "Bild"));
                    updateImages(temp);
            }}/>);
            }}>
                <SingleAddIcon/>
                <p>Bild hinzufügen</p>
            </div> : <></>}
        </div>
        </>;
}