import { openPopUp } from "../components/popUp";
import { sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

function deleteHelperEntry(id, onFinish) {
    sendRequest("helper-exchange/" + id, "DELETE", {}, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function getHelperEntry(id, onFinish) {
    sendRequest("helper-exchange/" + id, "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.exchange, r.data.categoryId);
        }else {
            openPopUp(r.message);
        }
    });
}

function getHelperEntries(onFinish) {
    sendRequest("helper-exchange/own", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.entries);
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function getHelperCategories(onFinish) {
    sendRequest("helper-exchange/categories", "GET", {}, (r) => {
        if(r.status == 200) {
            var categories = [];
            for(var i = 0; i < r.data.categories.length; i++) {
                categories.push(r.data.categories[i].name)
            }
            onFinish(categories)
        }else {
            openPopUp(r.message);
        }
    })
}

function updateHelperEntry(id, title, description, category, startTime, endTime, location, mail, phone, event, always, onFinish) {
    sendRequest("helper-exchange/" + id, "PATCH", {
        "mail": mail,
        "phone": phone,
        "title": title,
        "description": description,
        "startTime": startTime,
        "endTime": endTime,
        "location": location,
        "eventId": event,
        "category": category,
        "always" : always ? 2 : 1
    }, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function addHelperEntry(title, description, category, startTime, endTime, location, mail, phone, event, always, onFinish) {
    sendRequest("helper-exchange", "POST", {
        "mail": mail,
        "phone": phone,
        "title": title,
        "description": description,
        "startTime": startTime,
        "endTime": endTime,
        "location": location,
        "eventId": event,
        "category": category,
        "always" : always ? 2 : 1
    }, (r) => {
        if(r.status == 201) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

export {updateHelperEntry, deleteHelperEntry, getHelperEntry, getHelperEntries, addHelperEntry, getHelperCategories}