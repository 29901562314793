import React, {useState} from "react";
import BlueCircleImageIcon from "../../assets/icons/blueCircleImage";
import BlueCircleImageFullIcon from "../../assets/icons/blueCircleImageFull";
import ActionPage, { closeActionPage, openActionPage } from "../../components/actionPage";
import Button from "../../components/button";
import EditImageRow from "../../components/editImageRow";
import MultipleSwitch from "../../components/multipleSwitch";
import { openPopUp } from "../../components/popUp";
import TextField from "../../components/textField";
import { ArticleParagraph } from "../../content/articleParagraph";

import "../../style/createParagraph.css";

export default function CreateParagraphAction(props) {

    const [formatOption, setFormatOption] = useState(props.formatOption);
    const [text, setText] = useState(props.text);

    const formatOptions = [
        <>Abcdefg</>,
        <><BlueCircleImageIcon/>Abcd</>,
        <>Abcd<BlueCircleImageIcon/></>,
        <><BlueCircleImageIcon/>Ab<BlueCircleImageIcon/></>,
        <><BlueCircleImageIcon/><BlueCircleImageIcon/><BlueCircleImageIcon/></>,
        <><BlueCircleImageFullIcon/></>,
    ]

    return <ActionPage big className="create-paragraph">
        <MultipleSwitch defaultValue={formatOption} onChange={setFormatOption} label="Formatoptionen" options={formatOptions}/>
        <EditImageRow onCancel={() => {
            setTimeout(() => {
                openActionPage(<CreateParagraphAction text={text} formatOption={formatOption} onFinish={props.onFinish} images={props.images}/>);
            }, 30);
        }} maxImg={3} defaultValue={props.images} label="Bilder" onChange={(v) => {
            setTimeout(() => {
                openActionPage(<CreateParagraphAction text={text} formatOption={formatOption} onFinish={props.onFinish} images={v}/>);
            }, 30);
        }}/>
        <TextField defaultValue={text} onChange={setText} autogrow textarea label="Text" placeholder="Text eingeben"/>
        <Button onClick={() => {
            if(formatOption == -1) {
                openPopUp("Wähle eine Formatoption");
                return;
            }
            const paragraph = new ArticleParagraph(formatOption, text, props.images);
            if(!paragraph.isValid()) {
                openPopUp("Die Anzahl der Bilder + Text stimmen nicht mit der Formatoption überein");
                return;
            }
            props.onFinish(paragraph);
            closeActionPage();
        }}>Übernehmen</Button>
    </ActionPage>;
}