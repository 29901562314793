import React, { useEffect, useRef, useState } from "react";
import ClubListBox from "../components/clubListBox";
import Page from "../components/page";
import SearchHeader from "../components/searchHeader";
import { getAllClubs } from "../content/clubManager";

import "../style/dashboardClubList.css";

function DashboardClubList() {

    const [clubs, setClubs] = useState([]);
    const [search, setSearch] = useState("");
    const firstExecute = useRef(false);

    useEffect(() => {
        if(!firstExecute.current) {
            getAllClubs(setClubs);
            firstExecute.current = true;
        }
    });

    return <Page id="club-list">
        <div className="main-box">
            
            <SearchHeader setSearch={setSearch} button={<></>}/>
            {clubs.length == 0 ? <h2 className="empty">Es sind noch keine Vereinsseiten vorhanden</h2> : <></>}
            <div className="list content-box">
            {clubs.map(e => {
                if(search != "" && !e.name.toLowerCase().includes(search)) return;
                return <ClubListBox hidden={e.hidden} image={e.logo} name={e.name} owner={e.owner} id={e.id}/>;
            })}
            </div>
        </div>
    </Page>;
}

export default DashboardClubList;