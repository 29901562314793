import { openPopUp } from "../components/popUp";
import { sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

function getAllTextPairs(onFinish) {

    sendRequest("text", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.content);
        }else {
            openPopUp(r.message);
        }
    })
}

function updateTextPairs(pairs, onFinish) {
    sendRequest("text/multiple", "PATCH", {
        "map": pairs
    }, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

export {updateTextPairs, getAllTextPairs}