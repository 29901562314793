import React, {useState} from "react";
import Switch from "../../components/switch";
import ActionPage, { closeActionPage } from "../../components/actionPage";
import IconButton from "../../components/iconButton";
import DeleteIcon from "../../assets/icons/delete";
import TextField from "../../components/textField";
import EditImageBox from "../../components/editImageBox";
import Button from "../../components/button";
import { openPopUp } from "../../components/popUp";
import ImageBox from "../../components/imageBox";

export default function EditImageAction(props) {

    const [fullscreen, setFullscreen] = useState(props.attributes[0] == true || props.attributes[0] == "true");
    const [desc, setDesc] = useState(props.attributes[1]);

    return <ActionPage onCancel={props.onCancel}>
        <ImageBox image={props.image} fullscreen={fullscreen == true}/>
        <Switch defaultValue={fullscreen == true} left="Zuschneiden" right="Vollbild anzeigen" onChange={(v) => {
            setFullscreen(v);
        }}/>
        <IconButton onClick={() => {
            props.onDelete();
            closeActionPage();
        }}><DeleteIcon/></IconButton>
        <p>Bild löschen</p>
        <h2>Bildbeschreibung</h2>
        <h3>u.A. für Menschen mit eingeschränkter Sehkraft.</h3>
        <TextField defaultValue={desc} onChange={setDesc} textarea placeholder="Bildbeschreibung"/>
        <Button onClick={() => {
            if(desc.length == 0) {
                openPopUp("Die Beschreibung darf nicht leer sein");
                return;
            }
            props.onChange(fullscreen, desc);
            closeActionPage();
        }}>Übernehmen</Button>
    </ActionPage>;
}