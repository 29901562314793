import React, { useEffect, useRef, useState } from "react";
import {useNavigate} from "react-router-dom";

import Page from "../components/page";
import TextField from "../components/textField";
import IconButton from "../components/iconButton";
import SingleAddIcon from "../assets/icons/singleAdd";
import Button from "../components/button";
import AddIcon from "../assets/icons/add";
import EventListElement from "../components/eventListElement";
import { getAccessableEvents, getAccessableFutureEvents } from "../content/eventManager";

import "../style/dashboardEvents.css";
import SearchIcon from "../assets/icons/search";
import SearchHeader from "../components/searchHeader";
import DeleteEventAction from "./action/deleteEvent";
import { openActionPage } from "../components/actionPage";

function DashboardEvents() {

    const navigate = useNavigate();

    const [firstExecute, setFirstExecute] = useState(false);
    const [events, setEvents] = useState([]);
    const [showAllEvents, setShowAllEvents] = useState(false);
    const [search, setSearch] = useState("");
    const [eventsEmpty, setEventsEmpty] = useState(false);

    useEffect(() => {
        if(!firstExecute) {
            if(showAllEvents) {
                getAccessableEvents(setEvents);
            }else {
                
                getAccessableFutureEvents(setEvents);
            }
            getAccessableEvents((v) => {
                setEventsEmpty(v.length == 0);
            })
            setFirstExecute(true);
        }
        document.addEventListener("reloadEvents", () => {
            setFirstExecute(false);
        })
    })

    return <Page id="event-page">
        <div className="main-box">
            <SearchHeader button={<Button invert onClick={() => {
                navigate("/events/create/general");
            }}>Neuer Termin <SingleAddIcon/></Button>} setSearch={setSearch}></SearchHeader>
            <div className="event-list">
                {events.map(e => {
                    if(search.length > 0 && !e.title.toLowerCase().includes(search)) return;
                    return <EventListElement onCopy={() => {
                        navigate("/events/copy/" + e.id + "/general");
                    }} user={e.author} onEdit={() => {
                        navigate("/event/" + e.id + "/general");
                    }} onDelete={() => {
                        openActionPage(<DeleteEventAction id={e.id}/>);
                    }} id={e.id} image={e.image} title={e.title} date={e.start}/>
                })}
                {eventsEmpty ? <h2 className="empty">Es sind noch keine Termine eingetragen</h2> : <a onClick={() => {
                    if(showAllEvents) {
                        getAccessableFutureEvents(setEvents);
                    }else {
                        getAccessableEvents(setEvents);
                    }
                    setShowAllEvents(!showAllEvents);
                }}>Abgelaufene Termine {showAllEvents ? "ausblenden" : "anzeigen"}</a>}
            </div>

        </div>

    </Page>;

}

export default DashboardEvents;