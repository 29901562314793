import React, { useEffect, useRef, useState } from "react";

import {useNavigate} from "react-router-dom";

import SingleAddIcon from "../assets/icons/singleAdd";
import Page from "../components/page";
import Button from "../components/button";
import SearchHeader from "../components/searchHeader";
import HelperListBox from "../components/helperListBox";

import "../style/dashboardHelper.css"
import { getHelperEntries } from "../content/helperManager";

export default function DashboardHelper() {

    const [search, setSearch] = useState("");
    const [helper, setHelper] = useState([]);
    const firstExecute = useRef(false);

    const navigate = useNavigate();

    useEffect(() => {
        if(firstExecute.current) return;
        getHelperEntries(setHelper);
        firstExecute.current = true;
    });

    return <Page id="helper">
        <div className="main-box">
            <SearchHeader button={<Button invert onClick={() => {
                navigate("/aider/create");
            }}>Neues Hilfegesuch <SingleAddIcon/></Button>} setSearch={setSearch}></SearchHeader>
            {helper.length == 0 ? <h2 className="empty">Es sind keine Helfergesuche vorhanden</h2> : <></>}
            <div className="list content-box">
                {helper.map(e => {
                    return <HelperListBox id={e.id} endTime={e.endTime} startTime={e.startTime} location={e.location} title={e.title}/>
                })}
            </div>
        </div>
    </Page>;
}