import React, { useEffect, useRef, useState } from "react";

import SaveIcon from "../assets/icons/save";
import Page from "../components/page";
import { openPopUp } from "../components/popUp";
import SaveButton from "../components/saveButton";
import TextField from "../components/textField";
import { getAllTextPairs, updateTextPairs } from "../content/textManager";

import "../style/contentPage.css";
import DashboardSimpleContent from "./dashboardSimpleContent";

export default function ContentPage(props) {

    const [search, setSearch] = useState("");
    const firstExecute = useRef(false);
    const [pairs, setPairs] = useState({});
    const [changes, setChanges] = useState({});
    const [changeMade, setChangeMade] = useState(false);

    useEffect(() => {
        if(firstExecute.current) return;
        getAllTextPairs(setPairs);
        firstExecute.current = true;
    });

    return <DashboardSimpleContent updateSearch={setSearch} onClick={() => {
        updateTextPairs(changes, () => {
            openPopUp("Alle Texte wurden erfolgreich aktualisiert");
            setChanges({});
            setChangeMade(false);
        });
    }} changeMade={changeMade}>
        {Object.keys(pairs).map(key => {
            if(search != "" && !(key.toLowerCase().includes(search) || pairs[key].toLowerCase().includes(search))) return;
            console.log(search);
            return <>
                <h3>{key}</h3>
                <TextField autogrow textarea defaultValue={pairs[key]} placeholder="Text eingeben" onChange={(v) => {
                    var temp = changes;
                    if(v == pairs[key]) {
                        delete temp[key]
                    }else {
                        temp[key] = v;
                    }
                    setChanges(temp);
                    setChangeMade(Object.keys(temp).length > 0);
                }}/>
            </>
        })}
    </DashboardSimpleContent>

    return <Page id="content">
        <div className="main-box">
            <div className="content">
                {Object.keys(pairs).map(key => {
                    return <>
                        <h3>{key}</h3>
                        <TextField autogrow textarea defaultValue={pairs[key]} placeholder="Text eingeben" onChange={(v) => {
                            var temp = changes;
                            if(v == pairs[key]) {
                                delete temp[key]
                            }else {
                                temp[key] = v;
                            }
                            setChanges(temp);
                            setChangeMade(Object.keys(temp).length > 0);
                        }}/>
                    </>
                })}
            </div>
            <SaveButton onClick={() => {
                updateTextPairs(changes, () => {
                    openPopUp("Alle Texte wurden erfolgreich aktualisiert");
                    setChanges({});
                    setChangeMade(false);
                });
            }} changeMade={changeMade}/>
        </div>
    </Page>
}