import logo from './logo.svg';
import './App.css';
import {Route, Routes, HashRouter, Navigate, BrowserRouter} from "react-router-dom";

import LoginPage from './pages/login';

import "./style/fonts/filsonpro.css";
import "./style/global.css";
import "./style/colors.css";
import Header, { openFirstHeaderLink } from './components/header';
import Page from "./components/page";
import PopUp from './components/popUp';
import { useEffect, useState } from 'react';
import { getCategory, loadUser } from './content/loginManager';
import DashboardAccounts from './pages/dashboardAccounts';
import Dashboard from './pages/dashboard';
import DashboardAccountInfo from './pages/dashboardAccountInfo';
import DashboardClub from './pages/dashboardClub';
import DashboardEvents from './pages/dashboardEvents';
import DashboardClubList from './pages/dashboardClubList';
import DashboardCreateAccount from './pages/dashboardCreateAccount';
import DashboardCreateOwnClub from './pages/dashboardCreateOwnClub';
import DashboardChangePassword from './pages/dashboardChangePassword';
import { registerUnsafedHandler } from './content/unsafedChanges';
import DashboardCreateEventGeneral from './pages/event/createGeneral';
import DashboardCreateEventDate from './pages/event/createDate';
import CreateEvent from './pages/event/createEvent';
import DashboardArticles from './pages/dashboardArticles';
import CreateArticle from './pages/article/createArticle';
import EditEvent from './pages/event/editEvent';
import EditArticle from './pages/article/editArticle';
import DefaultPage from './pages/default';
import CopyArticle from './pages/article/copyArticle';
import ContentPage from './pages/content';
import DashboardHelper from './pages/dashboardHelper';
import DashboardHelperCreate from './pages/dashboardHelperCreate';
import ContentBase from './pages/contentbase/contentBase';
import CopyEvent from './pages/event/copyEvent';
import DashboardImagesPage from './pages/dashboardImages';
import DashboardSendNewsletterPage from './pages/dashboardSendNewsletter';

function App() {
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadUser(() => {
      setLoading(false);
    });
    registerUnsafedHandler();
  });

  if(loading) {
    return <></>;
  }

  return (
    <>
      <PopUp/>
      <BrowserRouter>

            <Routes>
              <Route path="/login" element={<LoginPage/>}/>
              <Route path="/club/:id/general" element={<Dashboard defaultActive={1}><DashboardClub active={0}/></Dashboard>}/>
              <Route path="/club/:id/sidebar" element={<Dashboard defaultActive={1}><DashboardClub active={1}/></Dashboard>}/>
              <Route path="/club/:id/content" element={<Dashboard defaultActive={1}><DashboardClub active={2}/></Dashboard>}/>
              <Route path="/club/createOwn" element={<Dashboard defaultActive={0}><DashboardCreateOwnClub/></Dashboard>}/>
              <Route path="/accounts" element={<Dashboard><DashboardAccounts/></Dashboard>}/>
              <Route path="/accounts/create" element={<Dashboard><DashboardCreateAccount/></Dashboard>}/>
              <Route path="/account/:username" element={<Dashboard><DashboardAccountInfo/></Dashboard>}/>
              <Route path="/events" element={<Dashboard><DashboardEvents/></Dashboard>}/>
              <Route path="/clubs" element={<Dashboard><DashboardClubList/></Dashboard>}/>
              <Route path="/change-password" element={<Dashboard><DashboardChangePassword/></Dashboard>}/>
              <Route path="/events/create/general" element={<Dashboard><CreateEvent general/></Dashboard>}/>
              <Route path="/events/create/date" element={<Dashboard><CreateEvent date/></Dashboard>}/>
              <Route path="/event/:id/general" element={<Dashboard><EditEvent general/></Dashboard>}/>
              <Route path="/event/:id/date" element={<Dashboard><EditEvent date/></Dashboard>}/>
              <Route path="/events/copy/:id/general" element={<Dashboard><CopyEvent general/></Dashboard>}/>
              <Route path="/events/copy/:id/date" element={<Dashboard><CopyEvent date/></Dashboard>}/>
              <Route path="/articles" element={<Dashboard><DashboardArticles/></Dashboard>}/>
              <Route path="/articles/create/general" element={<Dashboard><CreateArticle general/></Dashboard>}/>
              <Route path="/articles/create/content" element={<Dashboard><CreateArticle content/></Dashboard>}/>
              <Route path="/article/:id/general" element={<Dashboard><EditArticle general/></Dashboard>}/>
              <Route path="/article/:id/content" element={<Dashboard><EditArticle content/></Dashboard>}/>
              <Route path="/articles/copy/:id/general" element={<Dashboard><CopyArticle general/></Dashboard>}/>
              <Route path="/articles/copy/:id/content" element={<Dashboard><CopyArticle content/></Dashboard>}/>
              <Route path="/content" element={<Dashboard><ContentPage/></Dashboard>}/>
              <Route path="/aider" element={<Dashboard><DashboardHelper/></Dashboard>}/>
              <Route path="/aider/:id" element={<Dashboard><DashboardHelperCreate/></Dashboard>}/>
              <Route path="/aider/create" element={<Dashboard><DashboardHelperCreate/></Dashboard>}/>
              <Route path="/contentBase/general" element={<Dashboard><ContentBase general/></Dashboard>}/>
              <Route path="/contentBase/random" element={<Dashboard><ContentBase random/></Dashboard>}/>
              <Route path="/images" element={<Dashboard><DashboardImagesPage/></Dashboard>}/>
              <Route path="/newsletter" element={<Dashboard><DashboardSendNewsletterPage/></Dashboard>}/>
              <Route path="*" element={<DefaultPage/>}/>
              
            </Routes>

        </BrowserRouter>
    </>
  );
}

export default App;
