export default function ActivateIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <g id="Gruppe_53" data-name="Gruppe 53" transform="translate(-694 -637)">
      <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(694 637)" fill="#fff" stroke="#000" stroke-width="2">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
        <circle cx="12.5" cy="12.5" r="11.5" fill="none"/>
      </g>
      <line id="Linie_17" data-name="Linie 17" y2="13" transform="translate(706.5 647.5)" fill="none" stroke="#000" stroke-width="4"/>
      <path id="Pfad_11" data-name="Pfad 11" d="M10670,4916.691l5.691-5.691,5.691,5.691" transform="translate(-9969.191 -4264.7)" fill="none" stroke="#000" stroke-width="2"/>
    </g>
  </svg>
;  
}