var unsafedChanges = false;

function setUnsafedChanges(value) {
    unsafedChanges = value;
}

function registerUnsafedHandler() {
    window.addEventListener("beforeunload", (e) => {
        if(unsafedChanges) {
            e.preventDefault();
        }
    });
}

export {setUnsafedChanges, registerUnsafedHandler};