import Cookies from "universal-cookie";

const cookies = new Cookies();

function loadUserFromCookie(onFinish) {
    onFinish(cookies.get("username"), cookies.get("sessionId"), cookies.get("category"));
}

function storeUser(username, sessionId, category, storePassword) {
    cookies.set("username", username, { path: '/', expires: (storePassword ? getExpiresDate() : 0) });
    cookies.set("sessionId", sessionId, { path: '/', expires: (storePassword ? getExpiresDate() : 0) });
    cookies.set("category", category, { path: '/', expires: (storePassword ? getExpiresDate() : 0) })
}

function unstoreUser() {
    cookies.remove("username");
    cookies.remove("sessionId");
    cookies.remove("category");
}

function getExpiresDate() {
    // Cookies expires one year in future
    return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
}

export {storeUser, loadUserFromCookie, unstoreUser}