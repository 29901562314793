import React, { useEffect, useState, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getClubsByOwner } from "../content/clubManager";
import { getCategory, getUsername, logout } from "../content/loginManager";

import "../style/header.css";
import ChevronIcon from "../assets/icons/chevron";

export default function Header(props) {

    const navigate = useNavigate();

    const ulRef = useRef();
    const highlightRef = useRef();
    const [active, setActive] = useState(props.defaultActive ? props.defaultActive : 0);
    const [highlightOffset, setHighlightOffset] = useState(0);

    const [clubId, setClubId] = useState(-1);

    const [headerType, setHeaderType] = useState("EDITOR");
    const [mobileOpen, setMobileOpen] = useState(false);

    function getLiElement(number) {
        const children = ulRef.current.children;
        const lis = [];
        for(var i = 0; i < children.length; i++) {
            const current = children[i];
            if(current.nodeName == "LI") {
                lis.push(current);
            }
        }
        return lis[number];
    }

    function checkForMobile() {
        var el = document.body;
        let isOverflowing = el.clientWidth < el.scrollWidth;
        ulRef.current.parentElement.parentElement.classList.remove("header-mobile");
        if(window.innerWidth < 1530) {
            ulRef.current.parentElement.parentElement.classList.add("header-mobile");
        }
    }

    function updateHighlight() {
        //Locate highlight
        const activeElement = ulRef.current.children[active];
        highlightRef.current.style.width = (activeElement.offsetWidth + 20) + "px";
        highlightRef.current.style.top = (activeElement.getBoundingClientRect().top) + "px";
    }

    function hoverEffect(e) {
        /*const activeElement = getLiElement(active);
        console.log(e.target);
        if(!e.target) return;
        console.log(activeElement.getBoundingClientRect().top, e.target.getBoundingClientRect().top)
        if(activeElement.getBoundingClientRect().top > e.target.getBoundingClientRect().top) {
            setHighlightOffset(-4);
        }else if(activeElement.getBoundingClientRect().top < e.target.getBoundingClientRect().top) {
            setHighlightOffset(4);
        }*/

    }

    useEffect(() => {
        updateHighlight();
        if(getUsername() == undefined) return;
        getClubsByOwner(getUsername(), (r) => {
            if(r.length == 0) {
                setClubId(-1);
            }else {
                setClubId(r[0].id);
            }
        });
        setHeaderType(getCategory());
        checkForMobile();
        window.addEventListener("resize", checkForMobile);
    });
    
    var headerElements = getHeaderElements(headerType);

    return <header className={mobileOpen ? "mobile-open" : ""}>
        <div className="mobile-open-button" onClick={() => {
            setMobileOpen(!mobileOpen);
        }}>
            <ChevronIcon/>
        </div>
        <h1>BergamLaimer.info</h1>
        <h2>Dashboard</h2>
        <ul ref={ulRef}>
            {Object.keys(headerElements).map((e, i) => {
                var link = headerElements[e];
                if(link == null) {
                    return <hr/>;
                }
                if(link.includes(":clubId")) {
                    if(clubId == -1) {
                        link = "/club/createOwn";
                    }else {
                        link = link.replace(":clubId", clubId);
                    }
                }
                return <li onClick={() => {
                    if(link == ":logout") {
                        logout();
                    }else {
                        setActive(i);setHighlightOffset(0);navigate(link);
                    }
                }}>{e}</li>
            })}
        </ul>
        <div className="active-highlight" ref={highlightRef}/>
    </header>;
}

const adminHeader = {
    "Account Verwaltung": "/accounts",
    "Vereinsseiten": "/clubs",
    "hr1": null,
    "Aktuelles Beiträge": "/articles",
    "Termine": "/events",
    "Helferbörse": "/aider",
    "Inhalte": "/content",
    "hr2": null,
    "Passwort ändern": "/change-password",
    "Logout": ":logout"
}

const superAdminHeader = {
    "Account Verwaltung": "/accounts",
    "Vereinsseiten": "/clubs",
    "hr1": null,
    "Aktuelles Beiträge": "/articles",
    "Termine": "/events",
    "Helferbörse": "/aider",
    "Inhalte": "/content",
    "hr2": null,
    "Passwort ändern": "/change-password",
    "Logout": ":logout"
}

const clubHeader = {
    "Mein Verein": "/club/:clubId/general",
    "Meine Beiträge": "/articles",
    "Meine Termine": "/events",
    "hr": null,
    "Passwort ändern": "/change-password",
    "Logout": ":logout"
}

const editorHeader = {
    "Meine Beiträge": "/articles",
    "hr": null,
    "Passwort ändern": "/change-password",
    "Logout": ":logout"
}

function getHeaderElements(type) {
    if(type == "SUPERADMIN") {
        return superAdminHeader;
    }else if(type == "EDITOR") {
        return editorHeader;
    }else if(type == "CLUB") {
        return clubHeader;
    }else if(type == "ADMIN") {
        return adminHeader;
    }
}

function openFirstHeaderLink(type) {
    var clubId = -1;
    getClubsByOwner(getUsername(), (r) => {
        if(r.length == 0) {
            clubId = -1;
        }else {
            clubId = r[0].id;
        }
        var link = getHeaderElements(type)[Object.keys(getHeaderElements(type))[0]];
        if(link.includes("/club") && clubId == -1) {
            link = "/club/createOwn";
        }else {
            link = link.replace(":clubId", clubId);
        }
        window.open(link, "_self");
    });

}

export {getHeaderElements, openFirstHeaderLink}
