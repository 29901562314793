import React from "react";
import {useNavigate} from "react-router-dom";
import { formatDate } from "../content/apiManager";

import "../style/helperListBox.css";
import { formatClocktime } from "../utils/dateUtils";

export default function HelperListBox(props) {

    const startDate = new Date(props.startTime);
    const endDate = new Date(props.endTime);

    const navigate = useNavigate();

    return <div className="helper-list-box" onClick={() => {
        navigate("/aider/" + props.id);
    }}>
        <p>{formatDate(startDate)}<span>{formatClocktime(startDate, endDate)}</span></p>
        <p className="location">{props.location}</p>
        <p>{props.title}</p>
    </div>
}