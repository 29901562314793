import React, { useEffect, useState } from "react";
import ContentBase from "../contentbase/contentBase";
import CreateArticleContent from "./createArticleContent";
import CreateArticleGeneral from "./createArticleGeneral";
import { convertTagListToString } from "../../components/tagList";
import { castArticleParagraph, convertToArticleParagraphs } from "../../content/articleParagraph";
import { addArticle, editArticle, getArticleFormatJson } from "../../content/articleManager";
import { useNavigate, useParams } from "react-router-dom";
import { openPopUp } from "../../components/popUp";
import { json_changed } from "../../utils/arrayUtils";

export default function EditArticle(props) {

    const {id} = useParams();
    const navigate = useNavigate();

    const [dbData, setDbData] = useState();
    useEffect(() => {
        if(dbData != undefined) return;
        getArticleFormatJson(id, setDbData);
    })

    var attributes = {};
    for(var i = 0; i < Object.keys(props).length; i++) {
        attributes[Object.keys(props)[i]] = props[Object.keys(props)[i]];
    }
    attributes.data = dbData;
    attributes.back = "articles";
    attributes.title = "Neuen Beitrag bearbeiten";
    attributes.pages = [
        {
            name: "general",
            button: "Inhalt erstellen",
            children: <CreateArticleGeneral/>
        },
        {
            name: "content",
            button: "Beitrag aktualisieren",
            children: <CreateArticleContent/>
        }
    ];
    attributes.onFinish = (newData) => {
        const data = JSON.parse(JSON.stringify(newData));
        data.tagList = convertTagListToString(data.tags);
        data.sections = convertToArticleParagraphs(data.sections);
        var finalData = json_changed(dbData, data);
        editArticle(id, finalData.title, finalData.subtitle, finalData.author, finalData.tagList, finalData.sections, () => {
            navigate("/articles");
        })
    };

    if(!dbData) return <></>;

    return React.cloneElement(<ContentBase/>, attributes);
}