import React from "react";
import { useNavigate } from "react-router-dom";

import "../style/accountBox.css";

export default function AccountBox(props) {

    const navigate = useNavigate();

    return <div className={"account-box" + (props.disabled ? " disabled" : "")} onClick={() => {
        navigate("/account/" + props.name);
    }}>
        <h1>{props.name}</h1>
        <h2>{props.type}{props.online ? 
        <div className="online-state"/> : <></>}</h2>
    </div>
}