import React from "react";
import TagList from "../../components/tagList";
import TextField from "../../components/textField";

export default function CreateArticleGeneral(props) {
    return <>
        <TextField defaultValue={props.data.title} onChange={(v) => {
            props.submit("title", v);
        }} label="Titel" placeholder="Titel eingeben"/>
        <TextField defaultValue={props.data.subtitle} onChange={(v) => {
            props.submit("subtitle", v);
        }} label="Untertitel" placeholder="Untertitel eingeben"/>
        <TextField defaultValue={props.data.author} onChange={(v) => {
            props.submit("author", v);
        }} label="Autor" placeholder="Autor angeben"/>
        <TagList defaultValue={props.data.tags} onChange={(v) => {
            props.submit("tags", v);
        }} label="Schlagwörter"/>
    </>;
}