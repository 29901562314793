function getCurrentPageName() {
    var output = "";
    var split = window.location.href.split("/");
    for(var i = 3; i < split.length; i++) {
        output += split[i] + "/";
    }
    if(output.length > 0) {
        output = output.substring(0, output.length-1);
    }
    return output;
}

function formatDate(dateString) {
    var date = new Date(dateString);
    return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
}

export {getCurrentPageName, formatDate}