import React, { useEffect, useState } from "react";
import { openActionPage } from "../components/actionPage";
import EditImageBox from "../components/editImageBox";

import Page from "../components/page";
import { getStaticImages } from "../content/fileManager";

import "../style/staticImages.css";
import EditStaticImageAction from "./action/editStaticImage";

export default function DashboardImagesPage(props) {

    const [images, setImages] = useState([]);
    useEffect(() => {
        if(images.length > 0) return;
        getStaticImages(setImages);
    })

    return <Page id="static-images">
        <div className="main-box">
            {images.map((e, i) => {
                return <EditImageBox onClick={() => {
                    openActionPage(<EditStaticImageAction changeLogo={() => {
                        window.location.reload();
                    }} uuid={e}/>);
                }} editText="Bild bearbeiten" link={"-1/" + e}/>
            })}
        </div>
    </Page>
}