import React, {useEffect, useState} from "react";
import CreateDashboardPage from "../../components/createDashboardPage";
import { useLocation, useNavigate } from "react-router-dom";
import { getUrlSiblingLink } from "../../utils/generalUtils";
import CreateArticleGeneral from "../article/createArticleGeneral";
import { json_changed } from "../../utils/arrayUtils";

function sendSubmitMultiple(json) {
    console.log(json);
    const event = new CustomEvent("updateContentBaseData", { detail: json});
    document.dispatchEvent(event);

}

function sendSubmit(key, value) {
    var json = {};
    json [key] = value;
    sendSubmitMultiple(json);
}

export default function ContentBase(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const pages = props.pages;

    const [data, setData] = useState(props.data ? props.data : {});
    const [page, setPage] = useState({});

    function pageLoaded() {
        return Object.keys(page).length != 0;
    }
    function unloadPage() {
        setPage({});
    }
    function navigateTo(siblingLink) {
        navigate(getUrlSiblingLink(location.pathname, siblingLink));
        unloadPage();
    }

    const title = props.title;

    useEffect(() => {
        if(pageLoaded()) return;
        var prevPage = "";
        for(var i = 0; i < pages.length; i++) {
            var current = pages[i];
            current.id = i;
            current.prevPage = prevPage;
            current.nextPage = i < pages.length-1 ? pages[i+1].name : "";
            current.children = React.cloneElement(current.children, {
                data: data,
                submit: sendSubmit,
                submitMultiple: sendSubmitMultiple
            });
            if(props[current.name]) {
                setPage(current);
                console.log(i, current.id, current);
                return;
            }
            prevPage = current.name;
        }
    });

    useEffect(() => {
        document.addEventListener("updateContentBaseData", (e) => {
            var newDataTemp = JSON.parse(JSON.stringify(data));
            for(var i = 0; i < Object.keys(e.detail).length; i++) {
                var key = Object.keys(e.detail)[i];
                var value = e.detail[key];
                newDataTemp[key] = value;

            }
            setData(newDataTemp);
        });
    })

    const backButton = page.id > 0;
    const lastOne = page.id == pages.length-1;

    if(!pageLoaded()) return <></>;

    return <CreateDashboardPage back={props.back} onClick={() => {
        if(lastOne) {
            props.onFinish(data);
            return;
        }
        navigateTo(page.nextPage);
    }} stepBack={backButton ? "ich muss noch was ändern" : undefined} onStepBack={backButton ? () => {
        navigateTo(page.prevPage);
    } : undefined} finishButton={page.button} title={title}>
        {page.children}
    </CreateDashboardPage>;
}