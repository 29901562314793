import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import ActionPage, { closeActionPage } from "../../components/actionPage";
import Button from "../../components/button";
import { openPopUp } from "../../components/popUp";
import TextField from "../../components/textField";
import { deleteAccount } from "../../content/accountManager";
import {relogin} from "../../content/loginManager";

export default function DeleteAccountAction(props) {

    const navigate = useNavigate();

    const [password, setPassword] = useState();

    function executeDeleteAccount() {
        relogin(password, () => {
            deleteAccount(props.username, () => {
                closeActionPage();
                openPopUp("Account wurde gelöscht");
                navigate("/accounts");
            })
        });
    }

    return <ActionPage>
        <p>Sicher, dass du diesen Benutzeraccount löschen möchtest?</p>
        <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
        <TextField password onChange={setPassword} label="Admin Passwort" onEnter={executeDeleteAccount}/>
        <Button onClick={executeDeleteAccount}>Löschen</Button>
    </ActionPage>;

}