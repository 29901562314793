function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

function array_replace(arr, index, el) {
    var output = [];
    for(var i = 0; i < arr.length; i++) {
        if(i == index) {
            output.push(el);
            continue;
        }
        output.push(arr[i]);
    }
    return output;
}

function json_changed(arr1, arr2) {
    var output = {};
    for(var i = 0; i < Object.keys(arr2).length; i++) {
        var key = Object.keys(arr2)[i];
        if(arr1[key]) {
            if(arr1[key] != arr2[key]) {
                output[key] = arr2[key];
            }
        }else {
            output[key] = arr2[key];
        }
    }
    return output;
}

export {array_replace, array_move, json_changed}