import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import CreateDashboardPage from "../components/createDashboardPage";
import Page from "../components/page";
import TextField from "../components/textField";
import MultipleSelect from "../components/multipleSelect";
import {openPopUp} from "../components/popUp";
import { createAccount } from "../content/accountManager";
import {validateEmail} from "../utils/generalUtils";
import { getCategory } from "../content/loginManager";

function DashboardCreateAccount() {

    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [sendEmail, setSendEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [type, setType] = useState(-1);

    const [types, setTypes] = useState([]);

    useEffect(() => {
        if(types.length != 0) return;
        if(getCategory() == "SUPERADMIN") {
            setTypes([
                "Verein",
                "Editor",
                "Admin"
            ])
        }else {
            setTypes([
                "Verein",
                "Editor"
            ])
        }
    })

    function sendCreateAccount() {
        if(username.length == 0 || password.length == 0 || repeatPassword.length == 0 || type == -1) {
            openPopUp("Bitte fülle alle Felder aus");
            return;
        }
        if(password != repeatPassword) {
            openPopUp("Die eingegebenen Passwörter stimmen nicht überein");
            return;
        }
        if(sendEmail && !validateEmail(email)) {
            openPopUp("Die angegebene Email existiert nicht");
            return;
        }
        createAccount(username, password, type, sendEmail ? email : null, () => {
            navigate("/accounts");
        });
    }  

    return <CreateDashboardPage back="accounts" title="Neuen Account erstellen" finishButton="Account erstellen" onClick={sendCreateAccount}>
        <TextField label="Benutzername" placeholder="Benutzername eingeben" onChange={setUsername}/>
        <MultipleSelect items={types} onChange={setType} label="Art"/>
        <TextField generateButton password label="Passwort" placeholder="Passwort eingeben" onChange={setPassword}/>
        <TextField recieveGenerate password label="Passwort wiederholen" placeholder="Passwort erneut eingeben" onChange={setRepeatPassword}/>
        <TextField labelCheckboxOnChange={setSendEmail} labelCheckbox label="Anmeldelink an Email schicken" placeholder="Email Adresse eingeben" onChange={setEmail}/>
    </CreateDashboardPage>;
}

export default DashboardCreateAccount;