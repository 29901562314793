export default function DeactivateIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <g id="Gruppe_39" data-name="Gruppe 39" transform="translate(-1055 -559)">
      <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(1055 559)" fill="#fff" stroke="#000" stroke-width="2">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
        <circle cx="12.5" cy="12.5" r="11.5" fill="none"/>
      </g>
      <line id="Linie_17" data-name="Linie 17" x2="22" transform="translate(1056.5 571.5)" fill="none" stroke="#000" stroke-width="5"/>
    </g>
  </svg>
;  
}