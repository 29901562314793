import { useState } from "react";
import DeleteIcon from "../../assets/icons/delete";
import IconButton from "../../components/iconButton";
import ActionPage, { closeActionPage } from "../../components/actionPage";
import UploadIcon from "../../assets/icons/upload";
import { uploadImage } from "../../content/fileManager";
import EditImageBox from "../../components/editImageBox";
import { findRenderedDOMComponentWithClass } from "react-dom/test-utils";

export default function EditLogoAction(props) {

    const [loading, setLoading] = useState(false);

    function finish(file) {
        setLoading(true);
        uploadImage(file, (r) => {
            props.changeLogo(r);
            closeActionPage();
            setLoading(false);
        });
    }
    
    return <ActionPage loading={loading} draganddrop onDrop={finish}>
        {props.logo ? <EditImageBox link={props.logo} notclickable/> : <></>}
        {props.logo != "-1/2d8a97a4-1e0b-46a6-9335-8f3497eb2a16" ? <><IconButton onClick={() => {
            props.changeLogo("null");
            closeActionPage();
        }}><DeleteIcon/></IconButton>
        <p>Logo entfernen. Ein Platzhalter wird verwendet.</p></> : <></>}
        <IconButton onClick={() => {
            var input = document.createElement('input');
            input.type = 'file';
            input.addEventListener("change", ((e) => {
                var file = e.target.files[0];
                finish(file);
            }));
            input.click();
        }}><UploadIcon/></IconButton>
        <p>Neues Logo auswählen oder per Drag and Drop auf dieses Fenster schieben.</p>
    </ActionPage>;
}