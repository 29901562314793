import TextField from "./textField";
import IconButton from "./iconButton";
import Button from "./button";
import SearchIcon from "../assets/icons/search";


export default function SearchHeader(props) {
    return <div className="header search-header">
        <TextField placeholder="Suche" onChange={(v) => {
            props.setSearch(v.toLowerCase());
        }}/>
        <IconButton nohover><SearchIcon/></IconButton>
        {props.button}
        {props.children}
    </div>;
}