import { openPopUp } from "../components/popUp";
import { sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";
import { loadArticleParagraph } from "./articleParagraph";

function getArticle(id, onFinish) {
    sendRequest("article/" + id, "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data);
        }else {
            openPopUp(r.message);
        }
    });
}

function getArticleFormatJson(id, onFinish) {
    getArticle(id, (d) => {
        var data = {};
        data.title = d.title;
        data.subtitle = d.subtitle;
        if(!d.autoAuthor) {
            data.author = d.author;
        }
        var tags = [];
        for(var i = 0; i < d.tags.length; i++) {
            tags.push(d.tags[i].name);
        }
        data.tags = tags;
        var paragraphs = [];
        for(var i = 0; i < d.sections.length; i++) {
            var current = d.sections[i];
            paragraphs.push(loadArticleParagraph(current));
        }
        data.sections = paragraphs;
        onFinish(data);
    });
}

function getUserArticles(onFinish) {
    sendRequest("article/user", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.articles);
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function deleteArticle(id, onFinish) {
    sendRequest("article/" + id, "DELETE", {}, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function addArticle(title, subtitle, author, tagList, sections, onFinish) {
    if(sections == undefined || sections.length == 0) {
        openPopUp("Dein Artikel darf nicht leer sein");
        return;
    }
    sendRequest("article", "POST", {
        "title": title,
        "subtitle": subtitle,
        "author": author,
        "sections": sections,
        "tagList": tagList
    }, (r) => {
        if(r.status == 201) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function editArticle(id, title, subtitle, author, tagList, sections, onFinish) {
    console.log(tagList);
    sendRequest("article/" + id, "PATCH", {
        "title": title,
        "subtitle": subtitle,
        "author": author,
        "sections": sections,
        "tagList": tagList
    }, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

export {getArticleFormatJson, getUserArticles, addArticle, deleteArticle, getArticle, editArticle}