import React, { useEffect, useRef, useState } from "react";
import {useNavigate} from "react-router-dom";

import Page from "../components/page";
import TextField from "../components/textField";
import IconButton from "../components/iconButton";
import SingleAddIcon from "../assets/icons/singleAdd";
import Button from "../components/button";
import AddIcon from "../assets/icons/add";
import EventListElement from "../components/eventListElement";
import { getAccessableEvents, getAccessableFutureEvents } from "../content/eventManager";

import "../style/dashboardEvents.css";
import SearchIcon from "../assets/icons/search";
import SearchHeader from "../components/searchHeader";
import { getUserArticles } from "../content/articleManager";
import { formatDate } from "../content/apiManager";
import { openActionPage } from "../components/actionPage";
import DeleteArticleAction from "./action/deleteArticle";
import { getCategory } from "../content/loginManager";

function DashboardArticles() {

    const navigate = useNavigate();

    const [firstExecute, setFirstExecute] = useState(false);
    const [articles, setArticles] = useState([]);
    const [search, setSearch] = useState("");

    

    useEffect(() => {
        if(!firstExecute) {
            getUserArticles(setArticles);
            setFirstExecute(true);
        }
        document.addEventListener("reloadArticles", () => {
            setFirstExecute(false);
        })
    })

    return <Page id="event-page">
        <div className="main-box">
            <SearchHeader button={<Button invert onClick={() => {
                navigate("/articles/create/general");
            }}>Neuer Beitrag <SingleAddIcon/></Button>} setSearch={setSearch}></SearchHeader>
            <div className="event-list">
                {articles.map(e => {
                    if(search.length > 0 && !e.title.toLowerCase().includes(search)) return;
                    return <EventListElement onCopy={() => {
                        navigate("/articles/copy/" + e.id + "/general");
                    }} user={e.creator} onEdit={() => {
                        navigate("/article/" + e.id + "/general");
                    }} onDelete={() => {
                        openActionPage(<DeleteArticleAction id={e.id}/>);
                    }} image={e.titleImg} title={e.title} date={formatDate(new Date(e.time))}/>
                })}
                {articles.length == 0 ? <h2 className="empty">Es sind noch keine Aktuelles Artikel vorhanden</h2> : <></>}
            </div>

        </div>

    </Page>;

}

export default DashboardArticles;