import React from "react";
import ActionPage, { closeActionPage } from "../../components/actionPage";
import Button from "../../components/button";

export default function NotDeleteableAction() {
    return <ActionPage>
    <p>Dieser Account kann nicht gelöscht werden, da noch Beiträge oder Termine mit ihm verknüpft sind, oder da der Account eine Vereinsseite betreut.</p>
    <Button onClick={closeActionPage}>Alles klar</Button>

    </ActionPage>;
}