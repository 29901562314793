import React, { useState } from "react";

import "../style/multipleSwitch.css";

export default function MultipleSwitch(props) {

    const [active, setActive] = useState(props.defaultValue);

    return <div className="multiple-switch">
        {props.label ? <p>{props.label}</p> : <></>}
        <div className="options">
            {props.options.map((e, i) => {
                return <a onClick={() => {setActive(i);props.onChange(i);}} className={i == active ? "active" : ""}>{e}</a>;
            })}
        </div>
    </div>;
}