import { useEffect, useRef, useState } from "react";
import ActionPage, { closeActionPage } from "../../components/actionPage";
import Button from "../../components/button";
import CheckIcon from "../../assets/icons/check";
import { getAccessableFutureEvents } from "../../content/eventManager";

import "../../style/linkeEventAction.css";

export default function LinkEventAction(props) {

    const firstExecute = useRef(false);
    const [events, setEvents] = useState([]);
    const [active, setActive] = useState(-1);
    const [title, setTitle] = useState();

    useEffect(() => {
        if(firstExecute.current) return;
        getAccessableFutureEvents(setEvents);
        if(props.defaultValue) {
            setActive(props.defaultValue);
        }
        firstExecute.current = true;
    })

    return <ActionPage className="link-event-page">
        <h2>aus eigenen Terminen auswählen</h2>
        <div className="event-list">
            {events.map((e, i) => {
                return <p className={active == e.id ? "active" : ""} onClick={() => {
                    if(e.id == active) {
                        setActive(-1);
                        return;
                    }
                    setActive(e.id);
                    setTitle(e.title);
                }}>{active == e.id ? <CheckIcon/> : <></>}{e.title}</p>
            })}
        </div>
        <Button onClick={() => {
            props.onClick(active, title);
            closeActionPage();
        }}>Verknüpfen</Button>
    </ActionPage>
}