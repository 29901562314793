import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getArticleFormatJson } from "../../content/articleManager";
import { getOneEvent } from "../../content/eventManager";
import CreateEvent from "./createEvent";
import { getDateFromDate, getTimeFromDate } from "../../utils/dateUtils";
import { isPlaceholderImage } from "../../utils/generalUtils";

export default function CopyEvent(props) {

    const {id} = useParams();

    const [data, setData] = useState(undefined);
    const firstExecute = useRef(false);

    useEffect(() => {
        if(firstExecute.current) return;
        getOneEvent(id, (d) => {
            var startTime = new Date(Object.keys(d.time)[0]);
            var data = JSON.parse(JSON.stringify(d));
            data.image = isPlaceholderImage(d.image) ? undefined : d.image;
            data.date = getDateFromDate(startTime);
            data.starttime = getTimeFromDate(startTime);
            data.endtime = getTimeFromDate(new Date(d.time[Object.keys(d.time)[0]]));
            data.imageAttributes = d.imageAttribute;
            setData(data);
        });
        firstExecute.current = true;
    })

    if(data == undefined) {
        return <></>;
    }

    return <CreateEvent general={props.general} date={props.date} data={data}/>;

}