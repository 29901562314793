import React from "react";
import ContentBase from "../contentbase/contentBase";
import { createEvent } from "../../content/eventManager";
import { useNavigate } from "react-router-dom";
import { openPopUp } from "../../components/popUp";
import { getDateFromDateAndTimeString, getDateFromDateAndTimeStringAddDays, getDateFromNormalDateAndTimeString, getDateFromNormalDateAndTimeStringAddDays } from "../../utils/dateUtils";

import "../../style/createEvent.css";
import DashboardCreateEventGeneral from "./createGeneral";
import DashboardCreateEventDate from "./createDate";

export default function CreateEvent(props) {

    const navigate = useNavigate();

    var attributes = {};
    for(var i = 0; i < Object.keys(props).length; i++) {
        attributes[Object.keys(props)[i]] = props[Object.keys(props)[i]];
    }
    attributes.data = props.data ? props.data : {}
    attributes.back = "events";
    attributes.title = "Neuen Termin erstellen";
    attributes.pages = [
        {
            name: "general",
            button: "Datum wählen",
            children: <DashboardCreateEventGeneral/>
        },
        {
            name: "date",
            button: "Termin veröffentlichen",
            children: <DashboardCreateEventDate/>
        }
    ];
    attributes.onFinish = (data) => {
        var times = {};
        if(data.title == undefined || data.description == undefined) {
            openPopUp("Titel und Beschreibung müssen ausgefüllt sein");
            return;
        }
        if(data.date == undefined || data.starttime == undefined || data.endtime == undefined) {
            openPopUp("Datum und Zeit dürfen nicht leer sein");
            return;
        }
        var startDate = getDateFromDateAndTimeString(data.date, data.starttime);
        var endDate = getDateFromDateAndTimeStringAddDays(data.date, data.endtime, data.starttime <= data.endtime ? 0 : 1);
        times[(startDate.getTime()/1000) + ""] = (endDate.getTime()/1000) + "";
        var repeatDates = data.repeat;
        if(repeatDates != undefined) {
            
            for(var i = 0; i < repeatDates.length; i++) {
                var start = getDateFromNormalDateAndTimeString(repeatDates[i], data.starttime);
                var end = getDateFromNormalDateAndTimeStringAddDays(repeatDates[i], data.endtime, data.starttime <= data.endtime ? 0 : 1);
                times[(start.getTime()/1000) + ""] = (end.getTime()/1000) + "";
            }
        }
        createEvent(data.title, data.description, data.image, data.imageAttributes, data.location, "sport", times, () => {
            navigate("/events");
        });
    };

    return React.cloneElement(<ContentBase/>, attributes);
}