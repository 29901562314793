function jsonEmpty(json) {
    return !(!!json && Object.keys(json).length);
}

const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  function generatePassword() {
    var length = 12,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

function getUrlSiblingLink(url, sibling) {
  var split = url.split("/");
  var output = "";
  for(var i = 0; i < split.length - 1; i++) {
    output += split[i] + "/";
  }
  output += sibling;
  return output;
}

function isPlaceholderImage(imageLink) {
  return imageLink.split("/")[0] == "-1";
}

export {isPlaceholderImage, jsonEmpty, validateEmail, generatePassword, getUrlSiblingLink}