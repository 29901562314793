import React, {useEffect, useState} from "react";
import SingleAddIcon from "../../assets/icons/singleAdd";
import { openActionPage } from "../../components/actionPage";
import CreateArticleListElement from "../../components/createArticleListElement";
import { ArticleParagraph, castArticleParagraph } from "../../content/articleParagraph";
import { array_move, array_replace } from "../../utils/arrayUtils";
import CreateParagraphAction from "../action/createParagraph";

export default function CreateArticleContent(props) {

    const [defaultParagraphs, setDefaultParagraphs] = useState(props.data.sections);
    const [paragraphs, setParagraphs] = useState(props.data.sections ? props.data.sections : []);

    useEffect(() => {
        if(defaultParagraphs != props.data.sections) {

            setDefaultParagraphs(props.data.sections);
            setParagraphs(props.data.sections);
        }
    })

    return <div className="create-content">
        {paragraphs.map((v, i) => {
            var e = castArticleParagraph(v);
            return <CreateArticleListElement onChange={(v) => {
                setParagraphs([]);
                setTimeout(() => {
                    var arr = array_replace(paragraphs, i, v);
                    setParagraphs(arr);
                    props.submit("sections", arr);
                }, 1);
            }} onMove={(v) => {
                var arr = array_move(paragraphs, i, i + (v ? -1 : 1));
                setParagraphs([]);
                setTimeout(() => {
                    setParagraphs(arr);
                }, 1);
            }} left={i != 0} right={i != paragraphs.length - 1} imgs={e.getImages()} attributes={e.getAttributes()} text={e.getText()}/>
        })}
        <div onClick={() => {
            openActionPage(<CreateParagraphAction onFinish={(v) => {
                var temp = [];
                for(var i = 0; i < paragraphs.length; i++) {
                    temp.push(paragraphs[i]);
                }
                temp.push(v);
                setParagraphs(temp);
                props.submit("sections", temp);
            }} images={[]} text=""/>);
        }} className="create-paragraph paragraph">
            <SingleAddIcon/>
            <p>Absatz hinzufügen</p>
        </div>
    </div>;

}