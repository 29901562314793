class ClubImage {

    constructor(link, fullscreen, description) {
        this.link = link;
        this.fullscreen = fullscreen;
        this.description = description;
    }

    getLink() {return this.link;}
    isFullscreen() {return this.fullscreen;}
    getDescription() {return this.description;}

    setFullscreen(v) {
        this.fullscreen = v;
    }

    setDescription(v) {
        this.description = v;
    }

    getAttributes() {
        return [this.fullscreen, this.description];
    }

}

export default ClubImage;